<template>
  <v-card
    class="card text-center font-family-raleway-medium"
    style="position: relative"
  >
    <CoveredLoader v-if="isMounting" />
    <h3 class="mb-8 font-family-raleway-bold">
      {{ $t("create_campaign_page.set_budget_payment_step_title") }}
    </h3>
    <p class="mt-5 font-raleway-regular text-left">
      {{ $t("create_campaign_page.set_budget_payment_step_description") }}
    </p>
    <div class="d-flex justify-space-between">
      <h3>${{ budgetAmount }}</h3>
      <div style="font-size: 20px" class="font-raleway-regular">
        <span>${{ getTotalAmount }}/{{ $t("create_campaign_page.week") }}</span>
      </div>
    </div>
    <v-slider
      v-model="budgetAmount"
      max="200"
      min="1"
      height="10"
      class="mt-4"
      track-color="#DAE3F8"
      hide-details
    ></v-slider>
    <div
      class="d-flex justify-space-between total-card-style align-center mt-5"
    >
      <div class="text-left" style="width: 100%">
        <h3>{{ $t("create_campaign_page.total_forweek") }}</h3>
        <div class="d-flex align-baseline justify-space-between">
          <p class="font-raleway-regular mt-2 mb-0">
            {{ $t("create_campaign_page.will_be_charged") }}
            <strong>${{ getTotalAmount }}</strong>
            {{ $t("create_campaign_page.first_week_ads") }}
          </p>
          <h3 class="ml-2">
            <strong>${{ getTotalAmount }}</strong>
          </h3>
        </div>
      </div>

    </div>
    <v-divider class="my-7" />
    <div>
      <div class="d-flex justify-space-between">
        <span class="font-raleway-regular">{{
          $t("create_campaign_page.total_ad_spend")
        }}</span>
        <strong class="font-family-raleway-bold">${{ getTotalAmount }}</strong>
      </div>
      <div class="d-flex justify-space-between my-3">
        <span class="font-raleway-regular">{{
          $t("create_campaign_page.ad_spend_fee")
        }}</span>
        <strong class="font-family-raleway-bold">${{ getAmountByFee }}</strong>
      </div>
      <div
        class="d-flex justify-space-between my-3"
        v-if="parseToObjCampaign.isPlanChoosingFirst"
      >
        <span class="font-raleway-regular d-flex align-center"
          >{{ $t("create_campaign_page.subscription_fee") }} (
          <h5>{{ subscriptionName }}</h5>
          )</span
        >
        <strong class="font-family-raleway-bold">${{ subscriptionFee }}</strong>
      </div>
      <div class="d-flex justify-space-between my-3" v-if="isValidCoupon">
        <span class="font-raleway-regular"
          >{{ $t("promo") }} {{ couponId }} ({{ discountPercentage }}%
          Off)</span
        >
        <strong class="font-family-raleway-bold"
          >-{{ (subscriptionFee * discountPercentage) / 100 }}</strong
        >
      </div>
      <div class="d-flex justify-space-between font-family-raleway-bold">
        <h3 class="font-family-raleway-medium">
          {{ $t("create_campaign_page.total_charges") }}
        </h3>
        <h2 class="font-family-raleway-bold">
          ${{ getAmountWithFee - (subscriptionFee * discountPercentage) / 100 }}
        </h2>
      </div>
    </div>
    <!--    payment section -->
    <div>
      <div class="text-left align-center d-flex mt-7 justify-space-between">
        <strong class="font-family-raleway-bold">{{
          $t("create_campaign_page.payment_method")
        }}</strong>
        <v-tooltip top v-if="paymentMethodId && !addNewPayment">
          <template v-slot:activator="{ on }">
            <v-btn
              style="font-size: 14px !important"
              class="back-btn font-weight-bold px-5"
              @click="addNewPayment = true"
              color="primary"
              height="32"
              v-on="on"
              text
              small
            >
              <v-icon small>mdi-plus</v-icon>
              {{ $t("create_campaign_page.add_button") }}
            </v-btn>
          </template>
          <span>{{ $t("billing_page.add_new_payment") }}</span>
        </v-tooltip>
      </div>
    </div>
    <div
      v-if="userStripePaymentMethods.length > 0"
      style="max-height: 250px; overflow: auto"
    >
      <v-radio-group v-model="paymentMethodId">
        <div
          class="d-flex align-center justify-space-between hover-cards pa-2"
          v-for="(item, index) in userStripePaymentMethods"
          :key="index"
          @click="selectPaymentMethod(item.pM_ID)"
        >
          <div class="d-flex align-center">
            <!--            <v-checkbox-->
            <!--              v-model="item.isChecked"-->
            <!--              @click="selectPaymentMethod(item)"-->
            <!--            />-->
            <v-radio :value="item.pM_ID" class="py-0 my-0" />
            <img
              :src="cardIcon"
              alt=""
              :class="item.pM_ID ? 'd-block' : 'd-none'"
            />
            <span class="mx-5 font-family-raleway-bold">{{ item.number }}</span>
            <span class="font-weight-bold text-uppercase color-purple">{{
              item.cardBrand
            }}</span>
          </div>
          <div class="default-box ml-1" v-if="item?.default">
            {{ $t("default") }}
          </div>
        </div>
      </v-radio-group>
    </div>
    <v-row
      class="border border-16 px-2 py-1 mx-0 my-2 fd-col"
      :class="addNewPayment || !paymentMethodId ? 'd-flex' : 'd-none'"
    >
      <v-col md="8" sm="7" class="pa-0 ma-0">
        <div class="stripe-card pb-0 mb-0">
          <div id="cardNumber" class="mb-0 pb-2" />
        </div>
      </v-col>
      <v-col md="2" class="pa-0 ma-0">
        <div class="stripe-card pb-2 mb-0" id="cardExpire" />
      </v-col>
      <v-col md="2" class="pa-0 ma-0">
        <div class="stripe-card pb-2 mb-0" id="cardCVC" />
      </v-col>
    </v-row>
    <div v-if="!isHaveActivePlan">
      <div class="text-left mt-5" v-if="!addNewPayment || paymentMethodId">
        <strong class="font-family-raleway-bold">{{
          $t("create_campaign_page.coupon_id_text")
        }}</strong>
        <v-text-field
          v-model="couponId"
          class="input-border pb-0 mb-0 font-family-raleway-medium mt-2"
          :label="$t('create_campaign_page.coupon_id_input')"
          :class="isInvalidToken ? 'border-red' : ''"
          :error="isInvalidToken"
          @input="changeCouponVal"
          hide-details
          height="60"
          outlined
          required
        >
          <template v-slot:append>
            <v-btn
              class="add-btn"
              @click="checkCoupon"
              :loading="isCheckingPromo"
              >{{ $t("apply_btn") }}</v-btn
            >
          </template>
        </v-text-field>
        <span
          class="color-red font-family-raleway-medium ml-3"
          style="font-size: 13px"
          v-if="isInvalidToken"
          >{{ $t("create_campaign_page.coupon_invalid_text") }}</span
        >
      </div>
    </div>
    <v-row class="mt-2 mb-2 align-center">
      <v-col md="5">
        <v-btn
          v-if="addNewPayment || !paymentMethodId"
          class="back-btn text-decoration-underline"
          @click="discardChanges"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          v-else
          class="back-btn text-decoration-underline"
          @click="goBack"
        >
          {{ $t("back_button") }}
        </v-btn>
      </v-col>
      <v-col md="7" v-if="addNewPayment || !paymentMethodId">
        <v-btn
          class="button font-weight-bold px-5"
          :loading="isAddingNewPayment"
          @click="addNewPaymentMethod"
          height="60"
        >
          {{ $t("create_campaign_page.save_payment_button") }}
        </v-btn>
      </v-col>
      <v-col md="7" class="text-right" v-else>
        <v-btn
          class="button font-weight-bold px-5"
          @click="handleSavePayment"
          :loading="loading"
          height="60"
          >{{ $t("create_campaign_page.purchase_lunch") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import successfully from "../../../assets/icon/successfullyIcon.svg";
import cardIcon from "../../../assets/img/card.svg";
import { mapGetters } from "vuex";
import { authService } from "@/services/auth";
import { campaignService } from "@/services/campaign";
import { plansService } from "@/services/plans";
export default {
  name: "SetBudgetAndPayment",
  components: {
    CoveredLoader: () => import("@/components/CoveredLoader")
  },
  data() {
    return {
      budgetAmount: 5,
      loading: false,
      isAddingNewPayment: false,
      isMakeDefaultLoading: false,
      isMounting: false,
      isInvalidToken: false,
      isSelectingForBilling: false,
      addNewPayment: false,
      isValidCoupon: false,
      discountPercentage: 0,
      isCheckingPromo: false,
      isHaveActivePlan: false,
      successfully,
      cardIcon,
      parseToObjCampaign: {},
      userStripePaymentMethods: [],
      paymentMethodId: "",
      subscriptionName: "",
      couponId: "",
      subscriptionFee: 0,
    };
  },
  mounted() {
    this.isMounting = true;
    this.setUpStripe();
  },
  computed: {
    ...mapGetters(["getUser"]),
    getTotalAmount() {
      return this.budgetAmount * 7;
    },
    getAmountByFee() {
      return (this.getTotalAmount * 5) / 100;
    },
    getAmountWithFee() {
      if (this.parseToObjCampaign?.isPlanChoosingFirst) {
        return this.getTotalAmount + this.getAmountByFee + this.subscriptionFee;
      } else {
        return this.getTotalAmount + this.getAmountByFee;
      }
    },
  },
  async created() {
    const campaign = localStorage.getItem("campaign");
    const parseToObj = JSON.parse(campaign);
    this.parseToObjCampaign = parseToObj;
    try {
      await this.getUserInfoAndSetPaymentMethods();
    } catch (err) {
      const errCode = err.response?.statusText;
      this.$notify({
        group: "foo",
        type: "error",
        title: errCode ? errCode : "Something went wrong",
      });
    }
    this.isMounting = false;
  },
  methods: {
    async addNewPaymentMethod() {
      if (this.addNewPayment) {
        this.isAddingNewPayment = true;
        try {
          const response = await this.purchase();
          if (response === "error") return;
          await authService.attachMethods(response);
          this.paymentMethodId = response;
          const userResponse = await authService.getUserInfo();
          const { stripePaymentMethods } = userResponse.data;
          if (stripePaymentMethods.length > 0) {
            this.userStripePaymentMethods = stripePaymentMethods;
          }
          this.addNewPayment = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Card was successfully added!",
          });
          this.discardChanges();
        } catch (err) {
          const errCode = err.response?.statusText;
          this.$notify({
            group: "foo",
            type: "error",
            title: errCode ? errCode : "Something went wrong",
          });
        }
        this.isAddingNewPayment = false;
      } else {
        this.addNewPayment = true;
      }
    },
    async getUserInfoAndSetPaymentMethods() {
      const userResponse = await authService.getUserInfo();
      const { stripePaymentMethods, active } = userResponse.data;
      this.isHaveActivePlan = active;
      if (stripePaymentMethods.length > 0) {
        const findDefaultCard = stripePaymentMethods.find(
          (item) => item.default === true
        );
        this.userStripePaymentMethods = stripePaymentMethods;
        this.paymentMethodId = findDefaultCard?.pM_ID;
      }
      const { planId, planType } = this.parseToObjCampaign;
      if (planId) {
        const { data } = await plansService.getById(planId);
        const { annualFee, monthlyFee, name } = data;
        this.subscriptionName = name;
        if (planType === "annualFee") {
          this.subscriptionFee = annualFee / 12;
        } else {
          this.subscriptionFee = monthlyFee;
        }
      }
    },
    async handleSavePayment() {
      if (!this.paymentMethodId) return;
      this.loading = true;
      try {
        if (!this.isHaveActivePlan) {
          await this.createSubscription();
        }
        await this.pushCampaign();
        this.$notify({
          group: "foo",
          type: "success",
          title: "Successfully purchased and launched!",
        });
      } catch (err) {
        console.log(err.response);
        const { statusText } = err?.response;
        const data = err?.response?.data;
        if (statusText) {
          this.$notify({
            group: "foo",
            type: "error",
            title: statusText,
          });
        } else if (data) {
          this.$notify({
            group: "foo",
            type: "error",
            title: data,
          });
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Something went wrong!",
          });
        }
      }
      this.loading = false;
    },
    goBack() {
      if (this.getUser.active) {
        this.$router.replace("review-company");
      } else {
        this.$router.replace("choose-plan");
      }
    },
    async createSubscription() {
      const { planType, planId } = this.parseToObjCampaign;
      const createSubscriptionPayload = {
        paymentMethodId: this.paymentMethodId,
        productId: planId,
        annualStatus: planType === "annualFee",
        couponId: this.couponId,
      };
      if (this.couponId && this.isValidCoupon) {
        await authService.createSubscriptionWithCoupon(
          createSubscriptionPayload
        );
      } else {
        await authService.createSubscription({
          paymentMethodId: this.paymentMethodId,
          productId: planId,
          annualStatus: planType === "annualFee",
        });
      }
    },
    async checkCoupon() {
      this.isCheckingPromo = true;
      try {
        const response = await authService.checkCoupon(this.couponId);
        this.isInvalidToken = false;
        this.isValidCoupon = true;
        this.discountPercentage = response?.data?.discountPercentage;
      } catch (err) {
        this.isInvalidToken = true;
        this.loading = false;
        this.isValidCoupon = false;
      }
      this.isCheckingPromo = false;
    },
    async pushCampaign() {
      const customerId = localStorage.getItem("customerId");
      const budgetPayload = {
        amout: this.budgetAmount,
        currency: 0,
        period: 1,
      };
      const campaignPayload = {
        ...this.parseToObjCampaign,
        budget: budgetPayload,
        pmId: this.paymentMethodId,
      };
      delete campaignPayload.planId;
      delete campaignPayload.planType;
      delete campaignPayload.isPlanChoosingFirst;

      var result = await campaignService.create(campaignPayload, customerId);

      if (result.data.status == "requires_action") {
        var confirmResult = await this.stripe.confirmCardPayment(
          result.data.clientSecret
        );

        if (confirmResult.error) {
          //ERROR
          console.log(
            "SUCCESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS NOOOOOOOOOOOOOOOOOO"
          );
        } else {
          //SUCCESS
          await this.$router.push("all-done");
        }
      } else {
        await this.$router.push("all-done");
      }
    },
    discardChanges() {
      this.cardNumber.update({ value: "" });
      this.cardCvc.update({ value: "" });
      this.cardExpiry.update({ value: "" });
      this.addNewPayment = false;
    },
    setUpStripe: function () {
      const apiKey = process.env.VUE_APP_STRIPE_KEY;
      let stripe = window.Stripe(apiKey);
      this.stripe = stripe;
      this.elements = stripe.elements();
      this.cardNumber = this.elements.create("cardNumber", {
        showIcon: true,
        placeholder: "Card number",
        style: {
          base: {
            backgroundColor: "transparent",
            iconColor: "#262626",
            fontFamily: "Raleway medium, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
          },
          invalid: {
            iconColor: "Red",
            color: "Red",
          },
        },
      });
      this.cardExpiry = this.elements.create("cardExpiry");
      this.cardCvc = this.elements.create("cardCvc");
      this.cardNumber.mount("#cardNumber");
      this.cardExpiry.mount("#cardExpire");
      this.cardCvc.mount("#cardCVC");
    },
    purchase: async function () {
      const result = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.cardNumber,
        billing_details: {
          email: this.getUser ? this.getUser.email : "",
        },
      });
      if (result.status == "requires_action") {
        var confirmResult = await this.stripe.confirmCardPayment(
          result.data.clientSecret
        );

        if (confirmResult.error) {
          //ERROR
          console.log(
            "SUCCESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS NOOOOOOOOOOOOOOOOOO"
          );
        } else {
          //SUCCESS
          if (result.error) {
            this.$notify({
              group: "foo",
              title: result.error.message || "Something went wrong!",
              type: "error",
            });
            this.isAddingNewPayment = false;
            return "error";
          }
          const { id } = result.paymentMethod;
          return id;
        }
      } else {
        if (result.error) {
          this.$notify({
            group: "foo",
            title: result.error.message || "Something went wrong!",
            type: "error",
          });
          this.isAddingNewPayment = false;
          return "error";
        }
        const { id } = result.paymentMethod;
        return id;
      }
    },
    async makeDefault(paymentId) {
      this.isMakeDefaultLoading = true;
      try {
        await authService.setDefaultMethod(paymentId);
        await this.getUserInfoAndSetPaymentMethods();
      } catch (err) {
        const errCode = err.response?.statusText;
        this.$notify({
          group: "foo",
          type: "error",
          title: errCode ? errCode : "Something went wrong",
        });
      }
      this.isMakeDefaultLoading = false;
    },
    selectPaymentMethod(paymentMethodId) {
      this.paymentMethodId = paymentMethodId;
    },
    changeCouponVal() {
      this.isInvalidToken = false;
      this.isValidCoupon = false;
    },
  },
};
</script>
<style scoped lang="scss">
.total-card-style {
  padding: 20px;
  background-color: #f6f9fc;
  border-radius: 16px;
}
input:focus {
  outline: none !important;
}
.stripe-card {
  //padding: 8px 12px;
  //margin-right: 36px;
  line-height: 1.5;
  height: 50px;
  align-items: stretch;
  cursor: text;
  border-radius: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.disable-card {
  pointer-events: none;
}
.border {
  border: solid 1px rgb(154, 152, 152);
}
.default-box {
  border-radius: 10px;
  background-color: rgba(0, 87, 255, 0.1);
  color: #0057ff;
  height: 32px;
  padding: 5px 15px;
  font-size: 14px;
  font-family: "Raleway bold", sans-serif;
}
.hover-cards:hover {
  background-color: #dae3f8;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .fd-col {
    flex-direction: column;
  }
}

.add-btn {
  background-color: transparent !important;
  box-shadow: none;
  margin-bottom: 30px !important;
  color: #0057ff;
  font-weight: bold;
  height: 30px !important;
}
</style>
